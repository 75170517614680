@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.card {
    &Img {
        border-radius: .5rem;
        box-shadow: 0 2px .5rem rgba(0,0,0,.4);
        position: relative;
        flex-basis: 15rem;
        max-width: 15rem;
        height: 20.5rem;
        padding-top: 1.5rem;
        &Name {
            color: #fff;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 1.4rem;
            line-height: 1;
        }
        &Holder {
            width: 13rem;
            height: 13rem;
            border-radius: 10rem;
            margin: 1rem auto;
            background: #fff;
            position: relative;
      
                     
            img {
                position: absolute;
                width: 10rem;
                height: 10rem;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
            &WithAuthor {
                   img {
                    width: 9rem;
                    height: 9rem;
                    top: calc(50% - 1rem);
                   }
            }
            &RoundedFilled  {
                img {
                 width: 13rem!important;
                 height: 13rem!important;
                 border-radius: 10rem;
                 top: 50%;
                }
            }

            &Author {
                font-size: 1.2rem;
                position: absolute;
                bottom: 1.5rem;
                left: 50%;
                width: 100%;
                transform: translateX(-50%);
                z-index: 11;
                &White {
                    color: #fff;
                }
            }
        }
  
    }
    &Stats {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 1rem;
        position: relative;
        top: -2rem;
        &Item {
            background: #fff;
            border-radius: .5rem;
            line-height: 1;
            box-shadow: 0 1px 4px rgba(0,0,0,.3);
            padding: .5rem;
            flex-basis: 4rem;
            flex-shrink: 0;
            p {
                font-size: .8rem;
                text-transform: uppercase;
            }
            span {
                font-weight: bold;
            }
        }
    }
    &Check {
        margin: 1rem auto 0 auto;
        padding: 0 .5rem;
        display: block;
        width: 100%;
        border-radius: 3rem;
        border: 1px solid $primary; 
        color: $primary;
        height: 4rem;
        line-height: 4rem;
        svg {
            margin-top: .7rem;
        }
    }
}


 