@import '../../styles/variables';
@import '../../styles/mixins';

.gemPanel {
    background: #FFF;
    border-radius: 2rem;
    color: #4a4a4a;
    margin-top: 5px;
    padding: 5px 10px 5px 34px;
    position: relative;
    display: inline-block;
}

.gem {
    height: auto;
    left: 8px;
    top: 8px;
    position: absolute;
    width: 2rem;
}

.rewards {
    &Header {
        color: #fff;
        text-align: center;
        margin-bottom: 2rem;
    }
   &Nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 2rem;
        li {
            margin-bottom: 0.5rem;
            margin-right: .5rem;
        }
        button {
            font-size: 1.1rem!important;
            padding: .3rem 1rem!important;
        }
   }
   &Wrapper {
       display: flex;
       flex-wrap: wrap;
       justify-content: center;
       margin-left: -1rem;       
   }
   &Item {
        flex-basis: 15rem;
        max-width: 15rem;
        margin: 1rem;
        min-height: 25rem;
        text-align: center;
   }
}

.buyCardModal {
    align-items: center;
    display: flex;
    flex-direction: column;
    &ImgHolder {
        position: relative;
        flex-basis: 15rem;
        margin-bottom: 1rem;
        max-width: 15rem;
        min-height: 20.5rem;
        cursor: pointer;
    }
    &Text {
        font-size: 2.4rem;
        line-height: normal;
    }

    &ImgName {
        position: absolute;
        top: 12.2rem;
        text-align: center;
        font-size: 1.5rem;
        width: 10rem;
        color: #BBBBBB;
        text-transform: uppercase;
        font-weight: bold;
        line-height: 1;
        transform: translateX(-50%);
        left: 50%;
        height: 3.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &Buttons {
        margin-top: 1rem;
    }
}


.flipCard {
    background-color: transparent;
    perspective: 1000px;
    height: 100%;
  }
  
  .flipCardInner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }
  
  .flipCardFlipped > div {
    transform: rotateY(180deg);
  }
  
  .flipCardFront,
  .flipCardBack {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
  }
  
  .flipCardFront {
    z-index: 1;
  }
  
  .flipCardBack {
    transform: rotateY(180deg);
    z-index: 2;
    background: #fff;
  }
  
  .printBtn {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .printContainer {
    position: absolute;
    background: #FFF;
    left: 0;
    top: 0;
    width: 100%;
    min-height: 100vh;
    z-index: 200;
  }

  .printHeader {
    width: 100%;
    background: #f2f2f2;
  }
  .printHeaderInner {
    max-width: 920px;
    margin: 0 auto;
    padding: 20px;
  }

  @media print {
    .printHeader {
        display: none;
    }
}